import PlentyBody from '#app/components/typography/plenty-body'
import { mainColorsMapped } from '#app/features/plp-clerk/clerk-components/filters/colors-utils'

export const getColorFromName = (name: string): string => {
	const color = mainColorsMapped.find(color =>
		Object.values(color.name).includes(name),
	)
	return color ? color.rgb : ''
}

export const ColorVariants = (variants: any[]) => {
	const colorSet = new Set<string>()
	variants.forEach(variant => {
		variant.colors.forEach((color: string) => {
			colorSet.add(color)
		})
	})
	const validColors = Array.from(colorSet).filter(
		colorName => getColorFromName(colorName) !== '',
	)
	const colorsToShow = validColors.slice(0, 4)
	const extraColors = validColors.length - colorsToShow.length
	return (
		<div className="flex items-center">
			{colorsToShow.map((colorName: any) => (
				<div key={colorName} className="mr-2">
					<div
						className="h-4 w-4 rounded-full border border-opacity-20"
						style={{ backgroundColor: getColorFromName(colorName) }}
					/>
				</div>
			))}
			{extraColors > 0 && <PlentyBody size="xs">+{extraColors}</PlentyBody>}
		</div>
	)
}

export default ColorVariants

import { useTranslation } from 'react-i18next'
import PlentyBody from '#app/components/typography/plenty-body'
import { cn } from '#app/utils/misc'
import { type ClerkProduct } from '#types/product'

interface LowInStockLabelProps {
	product: ClerkProduct
	hidden?: boolean
}

function groupPricesBySize(stores: any[]): { size: string; stock: number }[] {
	const pricesBySize: { size: string; stock: number }[] = []

	stores.forEach(store => {
		store.prices.forEach((price: { size: string; stock: number }) => {
			const existingSize = pricesBySize.find(item => item.size === price.size)
			if (existingSize) {
				existingSize.stock += price.stock
			} else {
				pricesBySize.push({ size: price.size, stock: price.stock })
			}
		})
	})

	return pricesBySize
}
//TODO make it work when we have the stock data
const LowInStockLabel = ({ product, hidden }: LowInStockLabelProps) => {
	const stores = JSON.parse(product.data)
	let { t } = useTranslation('product_list_page')

	const isOneSize: boolean = stores.some((store: any) =>
		store.prices.some((price: any) => price.size === 'ONE SIZE'),
	)

	const sizes = groupPricesBySize(stores)
	if (isOneSize || sizes.length > 3) {
		return null
	}

	return (
		<>
			<div
				className={cn(
					'absolute bottom-10 z-20 whitespace-nowrap rounded-[40px] bg-orange-10 px-4 py-[2px] opacity-80 md:bottom-12',
				)}
			>
				<PlentyBody size="xs">{t('label_low_in_stock')}</PlentyBody>
			</div>
		</>
	)
}

export default LowInStockLabel

import { type ClerkFacet } from '#app/constants/clerk-api'
import { type LanguageKey } from '#types/lokalisation'

export const mainColorsMapped = [
	{ name: { en: 'Black', da: 'Sort' }, rgb: '#121212' },
	{ name: { en: 'White', da: 'Hvid' }, rgb: '#FFFFFF' },
	{ name: { en: 'Beige', da: 'Beige' }, rgb: '#f5ebe0' },
	{ name: { en: 'Blue', da: 'Blå' }, rgb: '#4A8AFF' },
	{ name: { en: 'Grey', da: 'Grå' }, rgb: '#B3BAC1' },
	{ name: { en: 'Pink', da: 'Rosa' }, rgb: '#ffafcc' },
	{ name: { en: 'Green', da: 'Grøn' }, rgb: '#a7c957' },
	{ name: { en: 'Brown', da: 'Brun' }, rgb: '#BF7F34' },
	{ name: { en: 'Red', da: 'Rød' }, rgb: '#F12A2B' },
	{ name: { en: 'Purple', da: 'Lilla' }, rgb: '#991EF9' },
	{ name: { en: 'Orange', da: 'Orange' }, rgb: '#F37A2A' },
	{ name: { en: 'Yellow', da: 'Gul' }, rgb: '#F6D02E' },
	{ name: { en: 'Metallic', da: 'Metalisk' }, rgb: '#C0C0C0' },
]

type LocalizedName = {
	[key in LanguageKey]: string
}
export type MappedColor = {
	term: LocalizedName
	count: number
	color: string
	options: string[]
}

export const specialMapping: { [key: string]: { [key: string]: string } } = {
	en: { rose: 'pink', gold: 'metallic', silver: 'metallic', turquoise: 'blue' },
	da: { rosa: 'pink', guld: 'metallic', sølv: 'metallic', turkis: 'blå' },
}

export const getKeysByValue = (
	specialMapping: { [key: string]: { [key: string]: string } },
	valueParam: string,
	lang: 'en' | 'da',
): string[] => {
	const keys: string[] = []

	Object.entries(specialMapping[lang]).forEach(([key, value]) => {
		if (value === valueParam.toLowerCase()) {
			keys.push(key)
		}
	})
	return keys
}
export const mapToPredefinedColors = (
	existingColors: ClerkFacet[],
	langKey: 'en' | 'da',
): MappedColor[] => {
	const colorMap: { [key: string]: { count: number; options: string[] } } = {}
	// Aggregate the counts and options for each mapped color term
	existingColors?.forEach(color => {
		const normalizedTerm = color.value.toLowerCase()
		const mappedTerm = specialMapping[langKey][normalizedTerm] || normalizedTerm

		if (
			mainColorsMapped.some(
				predefined => predefined.name[langKey].toLowerCase() === mappedTerm,
			)
		) {
			colorMap[mappedTerm] = colorMap[mappedTerm] || { count: 0, options: [] }
			colorMap[mappedTerm].count += color.count || 0
			colorMap[mappedTerm].options.push(color.value)
		}
	})

	// Map them to the predefined color objects
	return Object.entries(colorMap).map(([term, { count, options }]) => {
		const predefinedColor = mainColorsMapped.find(
			predefined => predefined.name[langKey].toLowerCase() === term,
		)

		// Ensure term is always a LocalizedName.
		const localizedTerm: LocalizedName = predefinedColor
			? predefinedColor.name
			: { en: term, da: term }

		return {
			term: localizedTerm,
			count,
			color: predefinedColor?.rgb || '',
			options: options.sort(), // Sort for better readability
		}
	})
}
